import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from './marker-icon-2x-blue.png';
import markerSelectIcon from './marker-icon-2x-red.png';
import shortlistMarkerIcon from './marker-icon-2x-violet.png';
import './Map.css';
import GoogleCSE from '../Card/GoogleSE';

const Map = ({ attractions, selectedAttraction, shortlist, onMarkerClick, allAttractions }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [markers, setMarkers] = useState([]);
  
  useEffect(() => {
    const tempAllAttractions = allAttractions.filter((attraction) => attraction.lat !== 'NO' && attraction.lon !== 'NO');
    if (tempAllAttractions.length === 0) {
      return () => {};
    }

    if (!mapInstance) {
      let minlat = 90, maxlat = 0, minlong = 180, maxlong = -180;

      for (const location of tempAllAttractions) {
        if (minlat > location.lat) minlat = location.lat;
        if (minlong > location.lon) minlong = location.lon;
        if (maxlat < location.lat) maxlat = location.lat;
        if (maxlong < location.lon) maxlong = location.lon;
      }
      
      let c1 = L.latLng(minlat, minlong);
      let c2 = L.latLng(maxlat, maxlong);

      const map = L.map('map').setView([0, 0], 12);
      map.fitBounds(L.latLngBounds(c1, c2));
      setMapInstance(map);

      // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   attribution: 'Map data &copy; OpenStreetMap contributors',
      //   maxZoom: 18,
      // }).addTo(map);

      L.tileLayer('https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL',
        maxZoom: 18,
      }).addTo(map);

    //   L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png', {
    //     attribution: '&copy; <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    //     minZoom: 1,
    //     maxZoom: 18
    // }).addTo(map);

    }
  }, [allAttractions]);

  useEffect(() =>{
    if (mapInstance) {
      // Clear existing markers
      markers.forEach(({ marker }) => {
        marker.remove();
      });
      setMarkers([]);

      const filteredAttractions = attractions.filter((attraction) => attraction.lat !== 'NO' && attraction.lon !== 'NO');
      if (filteredAttractions.length === 0) {
        return () => {};
      }

      const newMarkers = filteredAttractions.map((attraction) => {
        const marker = L.marker([attraction.lat, attraction.lon], { icon: L.icon({
          iconUrl: markerIcon,
          // iconSize: [39, 45],
          // iconAnchor: [20, 32],
          iconSize: [20, 32],
          iconAnchor: [10, 32],
          tooltipAnchor: [0, 0],
        }) }).addTo(mapInstance);

        const tooltip = L.tooltip({
          permanent: true,
          direction: 'top',
          className: 'marker-label-tooltip',
        }).setContent(attraction.name);

        marker.bindTooltip(tooltip).on('click', () => {
          onMarkerClick(attraction.name);
        }).closeTooltip();

        return { marker, tooltip };
      });

      setMarkers(newMarkers);
      // const group = new L.featureGroup(markers);
      // mapInstance.fitBounds(group.getBounds());

      // Deselect marker when map is clicked
      mapInstance.on('click', () => {
        onMarkerClick(null);
      });
    }
  }, [attractions, mapInstance]);



  useEffect(() => {
    markers.forEach(({ marker, tooltip }) => {
      const attractionName = tooltip.getContent();
      const isSelected = attractionName === selectedAttraction;
      const isInShortlist = shortlist.includes(attractionName);
  
      if (isSelected && !isInShortlist) {
        marker.setIcon(L.icon({ iconUrl: markerSelectIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] }));

        // marker.setIcon(L.icon({ iconUrl: markerSelectIcon, iconSize: [42, 48], iconAnchor: [20, 32], tooltipAnchor: [0, 0] }));
        if (!marker.isTooltipOpen()) {
          marker.openTooltip();
        }
      } else if (isInShortlist) {
        marker.setIcon(L.icon({ iconUrl: shortlistMarkerIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] }));

        // marker.setIcon(L.icon({ iconUrl: shortlistMarkerIcon, iconSize: [39, 45], iconAnchor: [20, 32], tooltipAnchor: [0, 0] }));
        if (!marker.isTooltipOpen()) {
          marker.openTooltip();
        }
      } else {
        marker.setIcon(L.icon({ iconUrl: markerIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] }));

        // marker.setIcon(L.icon({ iconUrl: markerIcon, iconSize: [39, 45], iconAnchor: [20, 32], tooltipAnchor: [0, 0] }));
        if (marker.isTooltipOpen()) {
          marker.closeTooltip();
        }
      }
    });

    // if (!selectedAttraction) {
    //   markers.forEach(({ marker }) => {
    //     if (!marker.isTooltipOpen()) {
    //       marker.openTooltip();
    //     }
    //   });
    // }
  }, [selectedAttraction, shortlist, markers, mapInstance]);

  const legendConfig = {
    [markerIcon]: 'Default Marker',
    [markerSelectIcon]: 'Selected Attraction',
    [shortlistMarkerIcon]: 'Shortlisted Attraction',
  };

  const renderLegend = () => {
    return (
      <div className="legend">
        <div className="legend-items">
          {Object.entries(legendConfig).map(([iconUrl, label]) => (
            <div key={iconUrl} className="legend-item">
              <img src={iconUrl} alt="Marker Icon" />
              <span style={{color: 'whitesmoke'}}>{label}</span>
              {/* <a href="https://www.flaticon.com/free-icons/location" title="location icons">Location icons created by Yogi Aprelliyanto - Flaticon</a> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div style={{position: 'relative', height: '100%', width: '100%'}}> 
      <div id="map" className="custom-map" style={{ height: '100%', width: '100%', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }} />
      {renderLegend()}
    </div>
  );
};

export default React.memo(Map);
