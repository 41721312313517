import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerSelectIcon from './marker-icon-2x-red.png';
import shortlistMarkerIcon from './marker-icon-2x-violet.png';
import './Map.css';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';


const ItineraryMap = ({ allAttractions, attractions, selectedAttraction, hotelMarkers = [], days, onDaySelection, attractionsData }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [markers, setMarkers] = useState([]);


  useEffect(() => {

    const updatedAllAttractions = [...allAttractions];

    for (const attraction of updatedAllAttractions) {
        // Find the corresponding attraction data in attractionsData by name
        const matchingAttractionData = attractionsData.find((data) => data.name === attraction['Attraction Name']);

        if (matchingAttractionData) {
            // Update the latitude and longitude of the attraction in updatedAllAttractions
            attraction.lat = matchingAttractionData.lat;
            attraction.long = matchingAttractionData.lon;
        }
    }


    const tempAllAttractions = updatedAllAttractions.filter((attraction) => attraction.lat !== 'NO' && attraction.lon !== 'NO');
    if (tempAllAttractions.length === 0) {
      return () => {};
    }



    if (!mapInstance) {
      let minlat = 90, maxlat = 0, minlong = 180, maxlong = -180;

      for (const location of tempAllAttractions) {
        if (minlat > location.lat) minlat = location.lat;
        if (minlong > location.long) minlong = location.long;
        if (maxlat < location.lat) maxlat = location.lat;
        if (maxlong < location.long) maxlong = location.long;
      }
      
      let c1 = L.latLng(minlat, minlong);
      let c2 = L.latLng(maxlat, maxlong);

      const map = L.map('map').setView([0, 0], 12);
      map.fitBounds(L.latLngBounds(c1, c2));
      setMapInstance(map);

      // setTimeout(() => { 
      //     map.invalidateSize(); 
      // }, 250); 

      // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   attribution: 'Map data &copy; OpenStreetMap contributors',
      //   maxZoom: 18,
      // }).addTo(map);
    //   L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png', {
    //     attribution: '&copy; <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    //     // minZoom: 1,
    //     maxZoom: 18
    // }).addTo(map);

    L.tileLayer('https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL',
      maxZoom: 18,
    }).addTo(map);
    
    }
  }, [allAttractions]);

  useEffect(() => {

    // const filteredAttractions = attractions.filter((attraction) => attraction !==undefined && attraction.lat !== 'NO' && attraction.lon !== 'NO' && attraction.lat !== undefined && attraction.lon !== undefined);
    const filteredAttractions = attractions.filter((attraction) => attraction.lat !== 'NO' && attraction.lon !== 'NO');



    if (filteredAttractions.length === 0 && (!hotelMarkers || hotelMarkers.length === 0)) {
      return () => { };
    }
 

    if (filteredAttractions.length === 0){
      return () => { };
    }

    const customIcon = L.icon({
      iconUrl: shortlistMarkerIcon,
      shadowUrl: markerShadow,
      // iconSize: [39, 45],
      iconSize: [20, 32],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [30, 35],
    });

    if (mapInstance){
    // Clear existing markers
    markers.forEach(({ marker }) => {
      marker.remove();
    });
    setMarkers([]);

    const attractionMarkers = filteredAttractions.map((attraction) => {

      const marker = L.marker([attraction.lat, attraction.long], { icon: customIcon }).addTo(mapInstance);

      const tooltip = L.tooltip({
        permanent: true,
        direction: 'left',
        className: 'marker-label-tooltip',
      }).setContent(attraction['Attraction Name']);

      marker.bindTooltip(attraction['Attraction Name'], {
        direction: 'left',
        permanent: true,
        className: 'marker-label-tooltip',
      });

      // markersRef.current.push();

      return { marker, tooltip };
    });
    
    const allMarkers = [...attractionMarkers];

    setMarkers(allMarkers);

  }
  }, [attractions, mapInstance, hotelMarkers]);

  useEffect(() => {
    markers.forEach(({ marker, tooltip }) => {
      const attractionName = tooltip.getContent();
      const isSelected =
        selectedAttraction && attractionName === selectedAttraction['Attraction Name'];

      if (isSelected) {
        marker.setIcon(
          L.icon({ iconUrl: markerSelectIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] })

          // L.icon({ iconUrl: markerSelectIcon, iconSize: [39, 45], iconAnchor: [20, 32], tooltipAnchor: [0, 0] })
        );
        marker.openTooltip();
      } else {
        marker.setIcon(
          L.icon({ iconUrl: shortlistMarkerIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] })

          // L.icon({ iconUrl: shortlistMarkerIcon, iconSize: [39, 45], iconAnchor: [20, 32], tooltipAnchor: [0, 0] })
        );
        marker.openTooltip();
      }
    });

    if (!selectedAttraction) {
      markers.forEach(({ marker }) => {
        marker.openTooltip();
      });
    }

  }, [selectedAttraction]);

  const legendConfig = {
    // [markerSelectIcon]: 'Selected Attraction',
    [shortlistMarkerIcon]: 'Selected Row Attraction',
  };

  const renderLegend = () => {
    return (
      <div className="legend">
        <div className="legend-items">
          {Object.entries(legendConfig).map(([iconUrl, label]) => (
            <div key={iconUrl} className="legend-item">
              <img src={iconUrl} alt="Marker Icon" />
              <span style={{color: 'whitesmoke'}}>{label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div style={{position: 'relative', height: '100%', width: '100%'}}> 
      <div id="map" className="custom-map" style={{  height: '100%', width: '100%', borderTopRightRadius: '2%', borderBottomRightRadius: '2%',}} />
      {renderLegend()}
    </div>
  );
};

export default React.memo(ItineraryMap);
