import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Purpose from '../Purpose/Purpose';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Guide from '../Guide/Guide';


const AutocompletePaper = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  borderRadius: '0px 0px 10px 10px',
  paddingLeft: '2%',
  marginLeft: '3%',
  marginRight: '3%',
}));

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: '10px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '10px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '10px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '10px solid var(--TextField-brandBorderColor)',
              paddingLeft: '50px',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '10px solid var(--TextField-brandBorderHoverColor)',
              borderRadius: '50px',
            },
            '&.Mui-focused:after': {
              borderBottom: '10px solid var(--TextField-brandBorderFocusedColor)',
              borderRadius: '50px',
            },
          },
        },
      },
    },
  });



const InputField = ({ cities }) => {
  const outerTheme = useTheme();
  const navigate = useNavigate();
  const [destination, setDestination] = useState('');
  const [responses, setResponse] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDestination, setIsDestination] = useState(false);

  const handleInputChange = (event, value) => {
    setDestination(value);
  };

  const searchDestination = async () => {
    if (destination){
    const response = await fetch('https://selenetravel.azurewebsites.net/destination/query', {
      // const response = await fetch('http://127.0.0.1:5000/destination/query', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ input: destination.country }),
    });

    if (response.ok) {
      const data = await response.json();

      setResponse(data.response);
      navigate('/countries', { state: { response: data.response, destination: destination.country } });
    }
  }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      searchDestination();
    }
  };

  const handleSearchIconClick = () => {
    searchDestination();
  };

  const handleExploreClick = async (selectedOptions) => {

    if (selectedOptions.length === 0) {
      selectedOptions = responses;
    }

    const response = await fetch('https://selenetravel.azurewebsites.net/destination/purpose', {
      // const response = await fetch('http://127.0.0.1:5000/destination/purpose', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ input: destination.city, purposes: selectedOptions }),
    });

    if (response.ok) {
      const data = await response.json();
      setResponse(data.response);
      navigate('/attractions', { state: { response: data.response, destination: destination.city } });
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerHeight < 900);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerHeight < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    // <div style={{display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>
      <div>

      <div className="search-container">
      
        <Box
          component="form"
          sx={{
            // display: 'flex',
            flex: '1 1 85%',
            alignItems: 'center',
            '& > :not(style)': {
              m: 1,
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '50px',
            },
          }}
          noValidate
          autoComplete="on"
        >
          
          <Autocomplete
            options={cities}
            getOptionLabel={(option) => option.country}
            PaperComponent={AutocompletePaper}
            renderInput={(params) => (
              <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                label="Where do you want to explore?"
                variant="filled"
                value={destination}
                onChange={handleInputChange}
                onKeyDown={handleEnterKeyPress}
                {...params}
              />
              </ThemeProvider>
            )}
            onChange={(event, value) => setDestination(value)}
          />
          
        </Box>
        <div style={{flex: '1 1 15%', display: 'flex', justifyContent: 'center', marginLeft: isMobile ? '3%' : ''}}>
        <IconButton variant='filled' size="small" style={{borderRadius: '50%', color: isMobile ? 'white' : 'black'}} onClick={handleSearchIconClick}>
          <SearchIcon style={{color:'black', fontSize: '200%', fontWeight: 'bolder'}}/>
          </IconButton>
      </div>

      </div>
      <Purpose options={responses} onExploreClick={handleExploreClick} />
    </div>
  );
};


export default React.memo(InputField);
