import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from './marker-icon-2x-blue.png';
import markerSelectIcon from './marker-icon-2x-red.png';
import shortlistMarkerIcon from './marker-icon-2x-violet.png';
import './Map.css';
import RoomIcon from '@mui/icons-material/Room';



const Map = ({ attractions, selectedAttraction, onMarkerClick, allAttractions }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [markers, setMarkers] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 900);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const tempAllAttractions = allAttractions.filter((attraction) => attraction.lat !== 'NO' && attraction.lon !== 'NO');
    if (tempAllAttractions.length === 0) {
      return () => {};
    }

    if (!mapInstance) {
      let minlat = 90, maxlat = 0, minlong = 180, maxlong = -180;

      for (const location of tempAllAttractions) {
        if (minlat > location.lat) minlat = location.lat;
        if (minlong > location.lon) minlong = location.lon;
        if (maxlat < location.lat) maxlat = location.lat;
        if (maxlong < location.lon) maxlong = location.lon;
      }
      
      let c1 = L.latLng(minlat, minlong);
      let c2 = L.latLng(maxlat, maxlong);

      const map = L.map('map').setView([0, 0], 15);
      map.fitBounds(L.latLngBounds(c1, c2));
      setMapInstance(map);

      L.tileLayer('https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL',
        maxZoom: 18,
      }).addTo(map);
    }
    // console.log(mapInstance);

  }, [allAttractions]);
  // console.log(mapInstance)

  useEffect(() =>{
    if (mapInstance) {
      // Clear existing markers
      markers.forEach(({ marker }) => {
        marker.remove();
      });
      setMarkers([]);

      const filteredAttractions = attractions.filter((attraction) => attraction.lat !== 'NO' && attraction.lon !== 'NO');
      if (filteredAttractions.length === 0) {
        return () => {};
      }

      const newMarkers = filteredAttractions.map((attraction) => {
        const marker = L.marker([attraction.lat, attraction.lon], { icon: L.icon({
          iconUrl: markerIcon,
          // iconSize: [39, 45],
          // iconAnchor: [20, 32],
          iconSize: [20, 32],
          iconAnchor: [10, 32],
          tooltipAnchor: [0, 0],
        }) }).addTo(mapInstance);

        const tooltip = L.tooltip({
          permanent: true,
          direction: 'top',
          className: 'marker-label-tooltip',
        }).setContent(attraction.city);

        marker.bindTooltip(tooltip).on('click', () => {
          onMarkerClick(attraction.city);
        }).closeTooltip();

        return { marker, tooltip };
      });

      setMarkers(newMarkers);

      // Deselect marker when map is clicked
      mapInstance.on('click', () => {
        onMarkerClick(null);
      });
    }
  }, [attractions, mapInstance]);

  const getCoords = (attractionName) => {
    console.log(allAttractions);
    console.log(attractionName);
    const matchingAttraction = allAttractions.find((data) => data.city === attractionName);
    
    if (matchingAttraction) {
      console.log(matchingAttraction);
      return [matchingAttraction.lat, matchingAttraction.lon];
    }
  }


  useEffect(() => {
    markers.forEach(({ marker, tooltip }) => {
      const attractionName = tooltip.getContent();
      const isSelected = attractionName === selectedAttraction;
  
      if (isSelected) {
        marker.setIcon(L.icon({ iconUrl: markerSelectIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] }));
        // mapInstance.setView(getCoords(selectedAttraction), 12);
        // mapInstance.fitBounds(markers.ge)
        // marker.setIcon(L.icon({ iconUrl: markerSelectIcon, iconSize: [39, 45], iconAnchor: [20, 32], tooltipAnchor: [0, 0] }));
        if (!marker.isTooltipOpen()) {
          marker.openTooltip();
        }
      } else {
        marker.setIcon(L.icon({ iconUrl: markerIcon, iconSize: [20, 32], iconAnchor: [10, 32], tooltipAnchor: [0, 0] }));

        // marker.setIcon(L.icon({ iconUrl: markerIcon, iconSize: [39, 45], iconAnchor: [20, 32], tooltipAnchor: [0, 0] }));
        if (marker.isTooltipOpen()) {
          marker.closeTooltip();
        }
      }
    });
  }, [selectedAttraction, markers, mapInstance]);

  const legendConfig = {
    [markerIcon]: 'Default Marker',
    [markerSelectIcon]: 'Selected Attraction',
    [shortlistMarkerIcon]: 'Shortlisted Attraction',
  };

  const renderLegend = () => {
    return (
      <div className="legend">
        <div className="legend-items">
          {Object.entries(legendConfig).map(([iconUrl, label]) => (
            <div key={iconUrl} className="legend-item">
              <img src={iconUrl} alt="Marker Icon" />
              <span style={{color: 'whitesmoke'}}>{label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div style={{ height: '100%', width: '100%'}}>
      <div id="map" className="custom-map" style={{ height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderTopRightRadius: isMobile? '' :'2%', borderBottomRightRadius: '2%' }} />
      {renderLegend()}
    </div>
  );
};

export default React.memo(Map);
