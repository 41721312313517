import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import ItineraryBoard from '../components/Board/Board';
import ResponsiveAppBar from '../components/AppBar';
import './ItineraryPage.css';
import ShowOnMapButton from '../components/Button/ShowOnMapButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';


const ItineraryPage = () => {
  const location = useLocation();
  const data2 = location.state?.response;
  const destination = location.state?.destination;
  const attractionsData = location.state?.attractionsData;

  const [showOnMap, setShowOnMap] = useState(true);


  const itineraryData = JSON.parse(data2);
  const toggleMap = () => {
    setShowOnMap(!showOnMap);
  };

  return (
    <div className='itineraries-page'>
      {/* <ResponsiveAppBar destination={destination} /> */}
      <AppBar className='my-title' position="static" sx={{ backgroundColor: 'rgb(0, 0, 0, 0.01)' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
            className='my-title'
              // variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 'auto',
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Ysabeau Infant', /* Use Ysabeau Infant font */
                fontWeight: 600,
                letterSpacing: '.2rem',
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Stanza
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'monospace',
                fontWeight: 600,
                letterSpacing: '.3rem',
                color: 'white',
                textTransform: 'capitalize',
              }}
            >
              {/* <div className='rearrange-title' style={{ flex: '1 1 2%', color: 'white', fontFamily: 'Ysabeau Infant' }}>
            Rearrange the cards to organise your trip..
          </div> */}
          {/* <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)',
                  }}
                    title='Rearrange the cards to organise your trip..'
                  >
                    <InfoIcon sx = {{ 
                      fontSize: window.innerWidth > 300 && window.innerHeight < 900 ? '1rem': '1.5rem',
}}/>
                  </IconButton> */}
              <ShowOnMapButton showOnMap={showOnMap} toggleMap={toggleMap} />

            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <ItineraryBoard destination={destination} initialItinerary={itineraryData.itinerary} attractionsData={attractionsData} showOnMap={showOnMap} />
    </div>
  );
};

export default React.memo(ItineraryPage);