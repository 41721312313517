import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import './Button.css';

const ShowMapButton = ({ showOnMap, toggleMap }) => {
  return (
    <Tooltip title={showOnMap ? 'Show List View' : 'Show Map View'} style={{ marginRight: '10px' }}>
      <IconButton
      className='showonmap-button-size'
        variant='outlined'
        onClick={toggleMap}
        sx={{
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.6)',
          borderRadius: '50%',
          color: 'white',
        }}
      >
        {showOnMap ? <ListIcon className='showonmap-icon-size' /> : <MapIcon className='showonmap-icon-size' />}
      </IconButton>
    </Tooltip>
  );
};

export default ShowMapButton;
