import React, { useState } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '460px',
    height: '330px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '15px'
  },
  navigation: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 1,
  },
  navigateIcon: {
    color: '#fff',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: '5px',
    margin: '0 10px',
    fontSize: '35px',
  },
}));

const ImageCarousel = ({ imageUrls }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const handleImageError = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  return (
    <div className={classes.root}>
      <img
        src={imageUrls[currentIndex].url}
        alt="carousel-image"
        className={classes.image}
        onError={handleImageError}
      />
      {imageUrls[currentIndex].attribution_req && (
        <span
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            fontSize: '10px',
            backgroundColor: '#989898',
          }}
          dangerouslySetInnerHTML={{
            __html: `${imageUrls[currentIndex].credit_line} <a href="${imageUrls[currentIndex].license_url}">${imageUrls[currentIndex].license}</a>`,
          }}
        />
      )}
      {imageUrls.length > 1 && (
        <div className={classes.navigation}>
          {currentIndex > 0 && (
            <NavigateBeforeIcon
              className={classes.navigateIcon}
              onClick={handlePrevious}
            />
          )}
          {currentIndex < imageUrls.length - 1 && (
            <NavigateNextIcon
              className={classes.navigateIcon}
              onClick={handleNext}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
