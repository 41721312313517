import React, {useState, useEffect} from 'react';
import { ExpandMore as ExpandMoreIcon, LocalDining as RestaurantIcon, DeleteOutline as DeleteIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import NearMeIcon from '@mui/icons-material/NearMe';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import './ExportItineraryPage.css';


const ExportAttractionCard = ({ attraction, showOnMap, index, attractionsData }) => {
  const getFirstLetter = (str) => {
    return str.charAt(0).toUpperCase();
  };


  const getImageData = (attractionName) => {
    const matchingAttraction = attractionsData.find((data) => data.name === attractionName);
    if (matchingAttraction) {
      return matchingAttraction.image_urls[0]?.url;
    }
    return '';
  };
  const getImageCredit = (attractionName) => {
    const matchingAttraction = attractionsData.find((data) => data.name === attractionName);
    if (matchingAttraction) {
      return matchingAttraction.image_urls[0]?.credit_line;
    }
    return '';
  };


  const getDescriptionData = (attractionName) => {
    const matchingAttraction = attractionsData.find((data) => data.name === attractionName);
    if (matchingAttraction) {
      return matchingAttraction.description;
    }
    return '';
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 750);
  const [isClicked, setIsClicked] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerWidth < 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    setIsClicked(!isClicked);
    return '';
  };

  return (
    <div style={{ marginBottom: '3%' }}>
      <div style={{ display: 'flex', flexDirection: isMobile? 'column': 'row'}}>
        <div className={showOnMap? 'card-container-showmap': 'card-container'}>
          {getImageData(attraction['Attraction Name']) ? (
            <div className='export-image-container'>
              <CardMedia component="img" sx={{ 
                              width: isMobile? '120px' : '160px',
                              height: isMobile? '120px' : '160px',
                              padding: isMobile? '1%': '1%',
                borderRadius: '50%',
                 }} image={getImageData(attraction['Attraction Name'])} loading="lazy" />
                <div style={{  
              cursor: 'pointer',
              position: 'absolute',
              bottom: '4%',
              // left: isMobile? '-34%': '-55%'

              }}>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)'}}
                  title={getImageCredit(attraction['Attraction Name'])}
                >
                  <InfoIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // backgroundColor: isMobile ? '#d5d4d4':'black',
              backgroundColor: 'black',

              borderRadius: '50%',
              width: isMobile? '120px' : '180px',
              height: isMobile? '120px' : '180px',
              fontSize: isMobile? '20px' : '36px',
              color: '#61567c',
              marginTop: '15%',
            }}>
              {getFirstLetter(attraction['Attraction Name'])}
            </div>
          )}

        </div>
        <div className={showOnMap? 'desc-margin-showmap': 'desc-margin'} style={{ flex: '1 1 75%', paddingLeft: '10px', paddingTop: '2%', paddingBottom: '20px', }}>
          <span>
            <h5 className={showOnMap ? 'name-size-showmap' : 'name-size'}>
              <PlaceIcon sx={{ verticalAlign: 'middle', 
              marginRight: isMobile ? '':'5px', 
              paddingBottom: '5px', color: '#8c74c7', fontSize: '140%' }} />
              {attraction['Attraction Name']}{attraction['Approximate Time'] && (<span style={{ fontSize: showOnMap ? '60%' : '70%', fontFamily: "Segoe UI Symbol" }}>    Approx. Time: {attraction['Approximate Time']}</span>)}
            </h5>
          </span>
          <p className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>{getDescriptionData(attraction['Attraction Name'])}</p>
            {/* {isMobile && !isClicked && (
              <div style={{display: 'flex',
                justifyContent: 'flex-end'}}><IconButton onClick={handleClick}>
              <ExpandMoreIcon sx={{color: '#bcb7b7', fontSize: '18px'}}/>
            </IconButton></div>
            )} */}
          
          {isMobile && isClicked && (
            <div>
            {attraction['Nearby dining'] && (
              <div>
                <RestaurantIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#bbbc80', fontSize: '18px'}} />
                <span className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>{attraction['Nearby dining']}</span>
              </div>
            )}
            {attraction['Nearby Transport'] && (
              <div>
                <NearMeIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#5f9ea0', fontSize: '18px' }} />
                <span className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>{attraction['Nearby Transport']}</span>
              </div>
            )}
            {attraction['Recommendations'] && (
              <div className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>Recommendations: {attraction['Recommendations']}
              </div>
            )}
            {attraction['Optional visits'] && (
              <div className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>Optional visits: {attraction['Optional visits']}
              </div>
            )}

            {/* <div style={{display: 'flex',
                justifyContent: 'flex-end'}}><IconButton onClick={handleClick}>
              <ExpandLessIcon sx={{color: '#bcb7b7', fontSize: '18px'}}/>
            </IconButton></div> */}
            </div>
          )}
          {!isMobile && (
            <div>
              {attraction['Nearby dining'] && (
              <div>
                <RestaurantIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#bbbc80' }} />
                <span className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>{attraction['Nearby dining']}</span>
              </div>
            )}
            {attraction['Nearby Transport'] && (
              <div>
                <NearMeIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#5f9ea0' }} />
                <span className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>{attraction['Nearby Transport']}</span>
              </div>
            )}
            {attraction['Recommendations'] && (
              <div className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>Recommendations: {attraction['Recommendations']}
              </div>
            )}
            {attraction['Optional visits'] && (
              <div className={showOnMap ? 'desc-size-showmap' : 'desc-size'}>Optional visits: {attraction['Optional visits']}
              </div>
            )}
            </div>
            )}
          
          
        </div>
      </div>
    </div>
  )
};

export default ExportAttractionCard;
