import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import { Icon } from 'leaflet';
import markerIcon from './marker-icon-2x-violet.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';


const Map = ({ attractions, storeMapCallback }) => {
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  useEffect(() => {
    console.log('hgh');
    const filteredData = {
        Morning: attractions.Morning.filter(
          (attraction) => attraction.lat !== "NO" && attraction.long !== "NO"
        ),
        Afternoon: attractions.Afternoon.filter(
          (attraction) => attraction.lat !== "NO" && attraction.long !== "NO"
        ),
        Evening: attractions.Evening.filter(
          (attraction) => attraction.lat !== "NO" && attraction.long !== "NO"
        ),
      };
    
      const filteredAttract = Object.values(filteredData)
        .flat()
        .map((attraction) => ({
          name: attraction["Attraction Name"],
          lat: attraction.lat,
          long: attraction.long,
        }));
  
    const filteredAttractions = filteredAttract.filter((attraction) => attraction.lat !== 'NO' && attraction.long !== 'NO');

    if (filteredAttractions.length === 0) {
      return () => {};
    }

    const map = L.map(mapRef.current);

    const createMap = (map) => {
      map.setView([filteredAttractions[0].lat, filteredAttractions[0].long], 13);

      // L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png', {
      //   attribution: '&copy; <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      //   // minZoom: 1,
      //   maxZoom: 18
      // }).addTo(map);

      L.tileLayer('https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL',
        maxZoom: 18,
      }).addTo(map);

      const customIcon = new Icon({
        iconUrl: markerIcon,
        shadowUrl: markerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      const markers = filteredAttractions.map((attraction) => {
        const marker = L.marker([attraction.lat, attraction.long], { icon: customIcon }).addTo(map);
  
        const tooltip = L.tooltip({
          permanent: true,
          direction: 'left',
          className: 'marker-label-tooltip',
        }).setContent(attraction.name);
  
        marker.bindTooltip(attraction.name, {
          direction: 'left',
          permanent: true,
          className: 'marker-label-tooltip',
        });
  
        return { marker, tooltip };
      });

      const group = new L.featureGroup(markers.map(x => x['marker']));
      map.fitBounds(group.getBounds());

      return markers;
    }
    storeMapCallback(map, createMap)

    // Clear existing markers
    markersRef.current.forEach(({ marker }) => {
      marker.remove();
    });
    markersRef.current = createMap(map)

    return () => {
      map.remove();
      storeMapCallback(map, null)
    };
  }, [attractions]);

  const legendConfig = {
    // [markerIcon]: 'Default Marker',
    // [marketSelectIcon]: 'Selected Attraction',
    // [shortlistMarkerIcon]: 'Shortlisted Attraction',
  };


  return (
  <div style={{position: 'relative', height: '100%', width: '100%'}}> 
    <div id="map" className="custom-map" style={{ borderTopRightRadius: '2%', borderBottomRightRadius: '2%', height: '100%', width: '100%' }} ref={mapRef} />
  </div>
  );
};

export default React.memo(Map);