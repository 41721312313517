import './CountryImageCard.css';
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import './ScrollBar.css';


const CardRow = ({ country, places, selectedCity, highlightedCity, selectedAttraction, onExploreClick }) => {
  const getFirstLetter = (str) => {
    return str.charAt(0).toUpperCase();
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const [hoveredCard, setHoveredCard] = React.useState(null);

  const handleCardClick = (city) => {
    selectedCity(city);
  };

  const handleCardHover = (city) => {
    setHoveredCard(city);
    highlightedCity(city);
  };

  const scrollToSelectedAttraction = () => {
    const selectedAttractionCard = document.getElementById(selectedAttraction);
    if (selectedAttractionCard) {
      selectedAttractionCard.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleExploreClick = (city) => {

    onExploreClick(city);
    setIsOpen(true);
  };

  const [isMobile, setIsMobile] = React.useState(window.innerWidth > 300 && window.innerWidth < 900);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
      console.log("ismobile", window.innerWidth > 300 && window.innerWidth < 900)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    scrollToSelectedAttraction();
  }, [selectedAttraction]);

  return (
    // <ImageList className='image-list-stuff' sx={{ width: '100%', height: '100%' }} cols={isMobile? 2 : 3} gap={32}>

    <ImageList className='image-list-stuff' cols={isMobile ? 2 : 3} >
      {places.map((item) => (
        <ImageListItem
          key={item.city}
          id={item.city}
          onClick={() => handleCardClick(item.city)}
          onMouseEnter={() => handleCardHover(item.city)}
          onMouseLeave={() => handleCardHover(null)}
          sx={{
            // position: 'relative',
            cursor: 'pointer',
            '&:hover img': {
              transform: 'scale(1.1)',
            },
          }}
        >
          {item.image_urls[0]?.url ? (

            <div className="zoom-effect">

              <img className='image-container'
                src={item.image_urls[0].url}
                alt={item.title}
                loading="lazy"
                style={{
                  borderRadius: '10px',

                  // height: '280px',
                  // width: '260px',
                  backgroundColor: 'rgb(0, 0, 0, 0.5)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />

              <ImageListItemBar
                className='info-pos'
                sx={{

                  background: 'none',
                  display: 'block',
                  left: 'auto',
                  right: 'auto',

                  // left: window.innerWidth > 1400 && window.innerWidth < 1700 ? '6%': window.innerWidth > 1700 ? '13%' : '',
                }}
                actionIcon={
                  <div>
                    <IconButton
                      // className='info-pos'
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      title={item.image_urls[0].credit_line}
                    >
                      <InfoIcon sx={{
                        fontSize: window.innerWidth > 300 && window.innerWidth < 900 ? '1rem' : '1.3rem',
                      }} />
                    </IconButton>
                    {hoveredCard === item.city && (

                      <div className="explore-button">
                        <Button variant="contained" color="primary" size="small"
                          sx={{
                            borderRadius: '10px',
                            color: 'white',
                            backgroundColor: '#5b68c3',
                            "&:hover": { backgroundColor: '#1f1f1f' }
                          }}
                          onClick={() => handleExploreClick(item.city)}

                        >
                          Explore
                        </Button>
                      </div>
                    )}
                  </div>
                }
                position="bottom"
                actionPosition="left"
              >

              </ImageListItemBar>
            </div>
          ) : (
            <div className="zoom-effect">

              <div
                className='image-container'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgb(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                  // width: '100%',
                  // height: '100%',
                  fontSize: '36px',
                  color: 'grey',
                }}
              >
                {hoveredCard === item.city && (
                  <div className="explore-button">
                    <Button variant="contained" color="primary" size="small"
                      sx={{
                        borderRadius: '10px',
                        color: 'white',
                        backgroundColor: '#5b68c3',
                        "&:hover": { backgroundColor: '#1f1f1f' },
                        bottom: '36px',

                      }}
                      onClick={() => handleExploreClick(item.city)}
                    >
                      Explore
                    </Button>
                  </div>
                )}
                {getFirstLetter(item.city)}
              </div>
            </div>
          )}
          <ImageListItemBar
            className='country-font'
            sx={{
              textAlign: 'center',
              background: 'none',
              borderRadius: '15px',
              '& .MuiImageListItemBar-title': {
                // fontSize: isMobile? '118%': '113%',
                fontWeight: 'bold',
                fontFamily: 'Ysabeau Infant',
                color: isMobile ? 'black' : 'beige',
              },
              width: '100%',
            }}
            title={item.city}
            position="below"
          >
          </ImageListItemBar>

        </ImageListItem>
      ))}

    </ImageList>
  );
};

export default React.memo(CardRow);
