import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import ItineraryButton from './Button/ItineraryButton';
import ContactButton from './ContactUs/Contact';
import './AppBar.css';

function ResponsiveAppBar({ destination, shortlist, attractionsData }) {


  return (
    // <AppBar position="static" sx={{ backgroundColor: '#464674' }}> - old purpple
    // <AppBar position="static" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>

    <AppBar className='height' position="static" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.01)' }}>
        {/* <Toolbar className='height'> */}
          
          <Typography
          className='title'
            // variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 'auto',
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Ysabeau Infant', /* Use Ysabeau Infant font */
              fontWeight: 600,
              letterSpacing: '.2rem',
              // backgroundColor: '#141414',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />Stanza {destination && `- ${destination}`}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.2rem',
              color: 'white',
              textTransform: 'capitalize',
            }}
          >
            {shortlist && <ItineraryButton destination={destination} shortlist={shortlist} attractionsData={attractionsData} />}

          </Typography>
        {/* </Toolbar> */}
    </AppBar>
  );
}
export default ResponsiveAppBar;
