import React, { useState, useEffect } from 'react';
import './ExportItineraryPage.css';
import { Card } from 'primereact/card';
import Map from '../Map/ExportItineraryMap';
import AttractionList from './AttractionList';


const ExportItinerary = ({ destination, itinerary, showOnMap, attractionsData, storeMapCallback }) => {
    const [allAttractions, setAllAttractions] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 900);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    useEffect(() => {
        const updatedAllAttractions = [...attractionsData];


        for (const attraction of updatedAllAttractions) {
            // Find the corresponding attraction data in attractionsData by name
            const matchingAttractionData = attractionsData.find((data) => data.name === attraction['Attraction Name']);
            
            if (matchingAttractionData) {
                // Update the latitude and longitude of the attraction in updatedAllAttractions
                attraction.lat = matchingAttractionData.lat;
                attraction.long = matchingAttractionData.lon;
                attraction.description = matchingAttractionData.description;
            }
        }
       
        setAllAttractions(updatedAllAttractions);
    }, [attractionsData])



    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginBottom: '10px',
                    marginRight: '10px',
                }}
            >
                <div style={{ flex: '1', textAlign: 'center', paddingTop: '0.6%', paddingBottom: '1%', color: 'white', fontFamily: 'Ysabeau Infant',
                      fontSize: window.innerWidth > 300 && window.innerWidth < 900 ? '100%' : '150%', 

             }}>
                        {destination}
                </div>
            </div>

            {Object.entries(itinerary)
                .filter(([day, attractions]) => {
                    // Check if there are attractions for any time slot
                    return (
                        attractions.Morning.length > 0 ||
                        attractions.Afternoon.length > 0 ||
                        attractions.Evening.length > 0
                    );
                })
                .map(([day, attractions]) => (
                    <div className='main-export-container' style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div className={showOnMap? 'export-container-showmap': 'export-container'}>
                            <Card
                                className={showOnMap? 'card-margin-showmap': 'card-margin'}
                                key={day}
                                title={day.replace(':', '')}
                                style={{
                                    marginBottom: '40px',
                                    borderRadius: '17px',
                                    // backgroundColor: isMobile? '#e1e1e1': 'rgb(0, 0, 0, 0.7)',
                                    // color: isMobile? 'black' : 'rgb(142 141 153)',
                                    backgroundColor:  'rgb(0, 0, 0, 0.7)',
                                    color: 'rgb(142 141 153)',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)'
                                }}
                                // className="day-card"
                            >
                                <div className="export-itinerary-attractions">
                                    {attractions.Morning.length > 0 && <AttractionList attractions={attractions.Morning} showOnMap={showOnMap} attractionsData={attractionsData} name="M O R N I N G" />}
                                    <br />
                                    {attractions.Afternoon.length > 0 && <AttractionList attractions={attractions.Afternoon} showOnMap={showOnMap} attractionsData={attractionsData} name="A F T E R N O O N" />}
                                    <br />
                                    {attractions.Evening.length > 0 && <AttractionList attractions={attractions.Evening} showOnMap={showOnMap} attractionsData={attractionsData} name="E V E N I N G" />}
                                </div>
                            </Card>
                        </div>
                        {showOnMap && (
                            <div style={{ margin: '0 5% 40px 0%', width: '100%' }}>
                                <Map attractions={attractions} storeMapCallback={storeMapCallback}></Map>
                            </div>
                        )}
                    </div>
                ))}

        </div>
    );
};

export default ExportItinerary;
