import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import InputField from './InputField';
import csvFile from './filtered_countries.csv'
import Guide from '../Guide/Guide';


const InputCitiesList = () => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      Papa.parse(csvFile, {
        download: true,
        complete: handleCSVData
    });
    };

    fetchData();
  }, []);

  const handleCSVData = (results) => {
    const data = results.data.slice(1); // Exclude the header row
   
    const parsedCities = data.map((row) => ({
      country: row[0],
    }));

    setCities(parsedCities);
  };

  return (
    <InputField cities={cities} />
  );
};

export default InputCitiesList;
