import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Block from '@material-ui/icons/Block';
import GetApp from '@material-ui/icons/GetApp';

import AdbIcon from '@mui/icons-material/Adb';
import './ItineraryPage.css';

import '@fontsource/princess-sofia';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    // backgroundImage: "url('./xx3.jpg')",
  },
}));

const PaymentSuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const payment_id = queryParams.get('payment-id');

  const [progress, setProgress] = useState("GENERATING");
  const [blob, setBlob] = useState(null);
  const [filename, setFilename] = useState(null);

  const downloadPdf = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 900);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://selenetravel.azurewebsites.net/destination/payment-success?id=' + payment_id, {
        // const response = await fetch('http://127.0.0.1:5000/destination/payment-success?id=' + payment_id, {
          method: 'GET',
          responseType: 'blob',
        });

        if (response.status !== 202 && response.status !== 201) {
          clearInterval(intervalId);
        }
        
        if (response.status === 200) {
          const pdfBlob = await response.blob();
          setProgress("DONE")

          const filename = response.headers.get('X-FILENAME');

          setBlob(pdfBlob)
          setFilename(filename)

          downloadPdf(pdfBlob, filename);
        } else if (response.status === 404) {
          setProgress("ERROR")
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setProgress("ERROR")
      }
    };


    const pollingInterval = 4000;

    fetchData();
    const intervalId = setInterval(fetchData, pollingInterval);

    return () => clearInterval(intervalId);
  }, []);

  const classes = useStyles();

  return (
    <div className='payment-page'>
      <AppBar className='my-title' position="static" sx={{ backgroundColor: 'rgb(0, 0, 0, 0.01)' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
            className='my-title'
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 'auto',
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Ysabeau Infant',
                fontWeight: 600,
                letterSpacing: '.2rem',
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Stanza
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <div style= {{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: "black" }}>

        <Backdrop className={classes.backdrop} open={true}>
          <div style={{ width: isMobile? '96%':'60%', height: '50%', minHeight: '100px', 'background': 'rgba(0, 0, 0, 0.2)', display: 'flex', flexFlow: 'column'}}>

            <div style={{ fontSize: '22pt' }}>
              <span style={{ color: 'green', paddingRight: '7px', verticalAlign: 'text-bottom' }}>
                <CheckCircle style={{ width: '22pt', height: '22pt' }} />
              </span>
              Thank you for your purchase!
            </div>

            <Divider variant="middle" style={{ backgroundColor: 'white', width: '35%', margin: '20px auto' }} />
            
            {progress == 'GENERATING' &&
              <div style={{ display: 'flex', flexFlow: 'column', height: '100%', alignItems: 'center' }}>
                <div style={{ margin: '0 10px', flex: '0 1 auto' }}>
                  Your PDF is Generating, please wait...
                </div>
                <div style={{ marginTop: '10px', display: 'flex', flex: '1 1 auto', alignItems: 'center' }}>
                  <CircularProgress color="inherit" />
                </div>
              </div>
            }

            {progress == 'ERROR' &&
              <div style={{ display: 'flex', flexFlow: 'column', height: '100%', alignItems: 'center' }}>
                <div style={{ margin: '0 10px', color: 'orange', flex: '0 1 auto' }}>
                  <div>Unfortunately, there was an error with creating the PDF.</div>
                  <div>Please try refreshing the page, or contact support.</div>
                </div>
                <div style={{ marginTop: '10px', color: 'red', display: 'flex', flex: '1 1 auto', alignItems: 'center' }}>
                  <Block style={{ width: '22pt', height: '22pt' }} />
                </div>
              </div>
            }

            {progress == 'DONE' &&
              <div style={{ display: 'flex', flexFlow: 'column', height: '100%', alignItems: 'center' }}>
                <div style={{ margin: '0 30px', color: '#70f4ad', flex: '0 1 auto' }}>
                  <div>The PDF has been generated and should be downloaded automatically.</div>
                  <div>If you are not able to access it, please try refreshing the page, or contact support.</div>
                </div>
                <div style={{ color: 'white', display: 'flex', flex: '1 1 auto', alignItems: 'center' }}>
                  <GetApp style={{ width: '27pt', height: '27pt', cursor: 'pointer' }} onClick={() => downloadPdf(blob, filename)} />
                </div>
              </div>
            }
            
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: '8pt', marginBottom: '3px' }}>
              <span style={{ display: 'inline-block' }}>Purchase reference:</span>&nbsp;<span style={{ display: 'inline-block' }}>#{ payment_id }</span>
            </div>

          </div>
        </Backdrop>
      </div>
    </div>
  );
};

export default React.memo(PaymentSuccessPage);