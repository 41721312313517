import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ExportItinerary from '../components/ExportItinerary/ExportItinerary';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import ItineraryButton from '../components/Button/ItineraryButton';
import '@fontsource/princess-sofia';
import ShowOnMapButton from '../components/Button/ShowOnMapButton';
import ExportPDFButton from '../components/Button/ExportToPDFButton';
import L from 'leaflet';

import html2pdf from 'html2pdf.js';
import './ItineraryPage.css';

const ExportItineraryPage = () => {
  const location = useLocation();
  const itineraryData = location.state?.response;
  const destination = location.state?.destination;
  const attractionsData = location.state?.attractionsData;

  const [showOnMap, setShowOnMap] = useState(false);
  const pageRef = useRef(null);

  const [maps, setMaps] = useState([]);

  const g = JSON.parse(itineraryData);
  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 900);

    
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(isMobile)

  const toggleMap = () => {
    setShowOnMap(!showOnMap);
  };

  const handleExportPDF = () => {
    const page = pageRef.current;
    const options = {
      margin: [0, 0, 0, 0],
      filename: 'itinerary.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 6, useCORS: true, allowTaint: true, backgroundColor: null },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };

    // var a = document.getElementById('map').cloneNode(true);
    var a = document.createElement("div");

    a.style.width = window.innerWidth + "px";
    // a.style.height = "400px";
    a.style.height = window.innerHeight + "px";
    // a.style.display = "none";

    document.body.append(a)
    a.style.display = "block";
    // a.remove()

    Object.values(maps)[0](L.map(a));
    // const map = maps[0];
    // map.invalidateSize();
    // map.fitBounds(L.latLngBounds(c1, c2));
    setTimeout(() => {

      html2pdf().set(options).from(a).save();
    }, 2000)
    // html2pdf().set(options).from(page).save();
  };

  const storeMap = (map, createMapCallback) => {
    if(createMapCallback !== null) {
      // maps.push({map, createMapCallback})
      maps[map] = createMapCallback
    }
    else {
      // setMaps(maps.filter(item => item !== map))
      delete maps[map]
    }
  }

  return (
    <div className='exportitinerary-page'>
      {/* <AppBar className='my-title' position="static" sx={{ backgroundColor: isMobile ? '#363253' : 'rgb(0, 0, 0, 0.01)' }}> */}
      <AppBar className='my-title' position="static" sx={{ backgroundColor: 'rgb(0, 0, 0, 0.01)' }}>

        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
            className='my-title'
              // variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 'auto',
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Ysabeau Infant', /* Use Ysabeau Infant font */
                fontWeight: 600,
                letterSpacing: '.2rem',
                color:  'white',
                textDecoration: 'none',
              }}
            >
              Stanza
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'monospace',
                fontWeight: 600,
                letterSpacing: '.3rem',
                color: 'white',
                textTransform: 'capitalize',
              }}
            >
              {!isMobile && (
                <ShowOnMapButton showOnMap={showOnMap} toggleMap={toggleMap} />

              )
              }
              <ExportPDFButton handleExportPDF={handleExportPDF} destination={destination} itinerary={g} showOnMap={showOnMap} attractionsData={attractionsData} storeMapCallback={storeMap} />

            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <div ref={pageRef}>
        <ExportItinerary destination={destination} itinerary={g} showOnMap={showOnMap} attractionsData={attractionsData} storeMapCallback={storeMap} />
      </div>
    </div>
  );
};

export default React.memo(ExportItineraryPage);