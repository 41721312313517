import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './Purpose.css';
import { Button } from '@mui/material';

const Purpose = ({ city, country, options, onExploreClick }) => {
  const [purposes, setPurposes] = useState([]);
  const [exploreClicked, setExploreClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (options) {
      const data = JSON.parse(options);
      setPurposes(data.purposes);
    }
  }, [options]);

  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.includes(option);
    if (isSelected) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleExploreClick = () => {
    setExploreClicked(true);
    onExploreClick(selectedOptions); 
  };

  return (
    <div>
      {purposes && purposes.length > 0 && !exploreClicked && (
        <div>
          <div className="purpose-title">What are your travel interests in {city}, {country}?</div>
          <div className="button-container" style={{ padding: '0 100px'}}>
            {purposes.map((option) => (
              <button
                key={option}
                type="button"
                className={`btn ${
                  selectedOptions.includes(option) ? 'btn-primary' : 'btn-light'
                } btn-lg`}
                onClick={() => handleOptionClick(option)}

                style={{
                  fontWeight: 500,
                  padding: '0.8% 1.5%',
                  opacity: 0.8,
                  textAlign: 'center',
                  margin: '1%',
                  fontSize: '95%',
                  borderRadius: '25px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)'

                }}
              >
                {option}
              </button>
              
            ))}
          </div>
          {/* <button
                type="button"
                className={`btn 'btn-primary'
                } btn-lg`}
                onClick={() => handleExploreClick()}
                style={{
                  fontWeight: 400,
                  padding: '1% 5%',
                  opacity: 0.9,
                  textAlign: 'center',
                  margin: '1%',
                  fontSize: '150%',
                  backgroundColor: '#1e671e',
                  borderRadius: '20px',
                  color: 'white',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)'
                }}
              >
                Explore
              </button> */}
              <Button variant='filled'
               onClick={() => handleExploreClick()}

              style={{
                fontWeight: 400,
                padding: '0.8% 5%',
                opacity: 0.9,
                textAlign: 'center',
                margin: '3%',
                fontSize: '100%',
                backgroundColor: '#1e671e',
                borderRadius: '25px',
                color: 'white',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)'
              }}
              > Explore </Button>
        </div>
      )}
    </div>
  );
};

export default Purpose;
