import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, LocalDining as RestaurantIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import NearMeIcon from '@mui/icons-material/NearMe';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './ItineraryCard.css';


const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
}));

const ItineraryCard = ({ index, attraction, onDragStart, onClick, onDelete, color }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleDeleteClick = () => {
    onDelete(index);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const cleanAttractionName = (attractionName) => {
    return attractionName.replace(/\([^)]*\)/, '').trim();
  };

  return (
    <Card
      className={classes.card}
      sx={{
        color: color,
        backgroundColor: 'rgb(0, 0, 0, 0.5)',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)',
        cursor: 'grab',
      }}
      draggable
      onDragStart={onDragStart}
      onClick={onClick}

    >
      <CardContent className='iti-card-padding' sx={{ width: '100%', }}>
        <Box sx={{ display: 'flex', alignItems: 'left'}}>
          <strong style={{ width: 'fit-content', float: 'left' }}>{cleanAttractionName(attraction['Attraction Name'])}</strong>
          <Typography variant="caption" sx={{ width: '30%', display: 'block', float: 'right' }}>
            <span className='iti-card-details'>{attraction['Approximate Time']}</span>
          </Typography>
          <IconButton
          className='iti-card-showmore'
        onClick={handleExpandClick}
        aria-expanded={expanded}
        // sx={{ marginTop: '10px' }}
      >
        <ArrowDropDownIcon className='iti-card-showmore' sx={{ color: color }} />
      </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <br />
          <div>{attraction['Attraction Details']}</div>
          <br />
          {attraction['Nearby dining'] && (
            <div>
              <RestaurantIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#205a2c' }} />
              {attraction['Nearby dining']}
            </div>
          )}
          {attraction['Nearby Transport'] && (
            <div>
              <NearMeIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} />
              {attraction['Nearby Transport']}
            </div>
          )}
          {attraction['Recommendations'] && (
            <div>
              <ReviewsIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#76b2c1' }} />
              Additional Recommendations: {attraction['Recommendations']}
            </div>
          )}
          {attraction['Optional visits'] && (
            <div>
              <ReviewsIcon sx={{ verticalAlign: 'middle', marginRight: '5px', color: '#76b2c1' }} />
              Optional visits: {attraction['Optional visits']}
            </div>
          )}
          <Button onClick={handleDeleteClick} variant="outlined" sx={{ color: '#6c97c4', marginTop: '10px', fontSize: '12px' }}>
            Remove
          </Button>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default ItineraryCard;
