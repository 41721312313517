import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Tooltip } from '@mui/material';
import { Alert } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './Button.css';

const ItineraryButton = ({ destination, shortlist, attractionsData }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showToast) {
      setToastMessage('Please add items to your trip');
    } else {
      setToastMessage('');
    }
  }, [showToast]);

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const navigate = useNavigate();

  const handleGenerateItitneraryClick = async () => {
    if (shortlist.length === 0) {
      setShowToast(true);
      return;
    }

    setLoading(true);

    const response = await fetch('https://selenetravel.azurewebsites.net/destination/itineraryv2', {
    // const response = await fetch('http://localhost:5000/destination/itineraryv2', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ input: destination, attractions: shortlist, attractionsData: attractionsData }),
    });

    if (response.ok) {
      const data = await response.json();
     
      navigate('/itinerary', { state: { response: data.response, destination: destination, attractionsData: attractionsData } });
    }
    setLoading(false);

  };
  return (
    <div>
      {/* <Tooltip title={loading ? '' : 'Rearrange the shortlisted attractions itinerary board.'}  */}
      {/* style={{ marginRight: window.innerWidth > 300 && window.innerHeight < 900 ? '' :'10px' }}> */}

        <Button className='generate-itinerary-button' variant="filled" onClick={handleGenerateItitneraryClick}
          // endIcon={<NavigateNextIcon />}
          disabled={loading}
          sx={{
            borderRadius: '10px',
            color: 'white',
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
          }}
          >
          {loading ? <span style={{ color: 'grey' }}>Please wait..</span> : 'Itinerary Board'}
        </Button>
      {/* </Tooltip> */}
      <Snackbar open={showToast} autoHideDuration={3000} onClose={handleCloseToast} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseToast} severity="info" >
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ItineraryButton;
