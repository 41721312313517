import React, { useState } from 'react';
import CountryImg from './CountryImg.png';
import CityImg from './CityImg.png';
import AttractionsImg from './Attractions.png';
import BoardImg from './Board.png';
import BoardDragImg from './BoardDrag.png';
import ItineraryImg from './Itinerary.png';
import ItineraryMapImg from './ItineraryMap.png';
import Typography from '@mui/material/Typography';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Guide.css';

const Guide = () => {
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
<div>


<div className="image-carousel-container">

<Slider {...carouselSettings} className="image-carousel">
                <div>
                <h4>Pick a country, any country. </h4>
                    We haven't got them all.. Yet!
                
                    <img className='image-carousel-img' src={CountryImg} alt="Country 1" />
                </div>
                <div>
                <h4>Choose the city with our snazzy map view. </h4>
                    No more accidental visits to tourist traps – unless you're into that sort of thing.

                    <img className='image-carousel-img' src={CityImg} alt="Country 2" />
                </div>
                <div>
                <h4>Explore and Plan with Tabs and Wishlist. </h4>
                    Discover the world through our themed tabs. Add your favorites to the Wishlist because nothing says "I'm serious about this trip" like a virtual list.
                    <img className='image-carousel-img' src={AttractionsImg} alt="Country 3" />
                </div>
                <div>
                <h4>Drag and Drop them by morning, afternoon, and evening.</h4>
                    And pretend it's not more stressful than real-life travel.

                    <img className='image-carousel-img' src={BoardDragImg} alt="Country 3" />
                </div>
                <div>
                <h4>The moment of truth! </h4>
                    Preview your masterpiece before you embark on a journey filled with regret and second-guessing. Because spontaneity is overrated.

                    <img className='image-carousel-img' src={ItineraryImg} alt="Country 3" />
                </div>
                <div>
                <h4>Your meticulously crafted itinerary now has a map view.</h4>
                    So now you can see your chaotic adventure visually laid out. Now, for the bargain price of 1.80 euros, you can immortalize your itinerary in PDF form. 

                    <div>
                    Happy travels... or planning, or whatever you're doing here!
                    </div>

                    <img className='image-carousel-img' src={ItineraryMapImg} alt="Country 3" />
                </div>
            </Slider>
</div>
</div>
    );
};

export default Guide;