import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useRef } from 'react';

function GoogleCSESmall({ query, id }) {
    const searchBoxRef = useRef(null);

    const slugify = (str) => {
        return String(str)
            .normalize('NFKD') // split accented characters into their base characters and diacritical marks
            .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
            .trim() // trim leading or trailing whitespace
            .toLowerCase() // convert to lowercase
            .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/-+/g, '-'); // remove consecutive hyphens
    }

    const getGname = (slug) => {
        return "gname-" + slug
    }

    const slug = slugify(id);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cse.google.com/cse.js?cx=b7885b9f43f724db2';
        script.async = true;

        script.onload = () => {
            setTimeout(() => {
                const searchContainer = document.getElementById(slug);

                var componentConfig = {
                    div: slug,
                    tag: 'search',
                    gname: getGname(slug)
                };
                window.google.search.cse.element.render(componentConfig);
                
                try {
                const searchButton = searchContainer.querySelector('.gsc-search-button');
                    
                searchButton.addEventListener('click', () => {
                    var gsearch = window.google.search.cse.element.getElement(getGname(slug));
                    gsearch.execute(query);
                });
            } catch (error){
                let retries = 0;
                const maxRetries = 3;

                const retryOperation = () => {
                    if (retries < maxRetries) {
                        setTimeout(() => {
                            try {
                                // Retry the operation
                                const searchButton = searchContainer.querySelector('.gsc-search-button');
                    
                searchButton.addEventListener('click', () => {
                    var gsearch = window.google.search.cse.element.getElement(getGname(slug));
                    gsearch.execute(query);
                });
                            } catch (retryError) {
                                // Increment retries and retry again
                                retries++;
                                retryOperation();
                            }
                        }, 500);
                    }

                };
                retryOperation();
            }
            }, 1000);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [query, id]);

    return (
        <div>
            <div id={slug} data-gname={getGname(slug)}></div>
        </div>
    );
}

export default GoogleCSESmall;
