import React from 'react';
import { Button, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import html2pdf from 'html2pdf.js';
import L from 'leaflet';
import DownloadButton from '../StripePay/DownloadButton';


const ExportPDFButton = ({ handleExportPDF, destination, itinerary, showOnMap, attractionsData, storeMapCallback}) => {
  const exportToPDF = () => {
    const options = {
      margin: [0, 0, 0, 0],
      filename: 'itinerary.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 6, useCORS: true, allowTaint: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };
    var a = document.getElementById('map')//.cloneNode(true);
    a.style.width = window.innerWidth + "px";
    a.style.height = "400px";
    a.style.height = window.innerHeight + "px";
    const map = L.map(a);
    map.invalidateSize();
    // map.fitBounds(L.latLngBounds(c1, c2));
    setTimeout(() => {
      html2pdf().set(options).from(a).save();
    }, 1000)


  };

  return (
    <DownloadButton destination={destination} itinerary={itinerary} showOnMap={showOnMap} attractionsData={attractionsData} />
  );
};

export default ExportPDFButton;
