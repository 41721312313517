import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
} from '@mui/material';
import { Tooltip } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import ItineraryMap from '../Map/ItineraryMap';
import ViewFullItineraryButton from '../Button/ViewFullItineraryButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ItineraryCard from '../Card/ItineraryCard';
import './Board.css';



const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  dayContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandIcon: {
    marginLeft: theme.spacing(1),
  },
  draggable: {
    border: '2px dashed #aaa',
    cursor: 'grab',
  },
  board: {
    display: 'grid',
    gridTemplateRows: 'minmax(20px, auto) 1fr',
  },
}));

const ItineraryBoard = ({ destination, initialItinerary, attractionsData, showOnMap }) => {
  const classes = useStyles();
  // const [showOnMap, setShowOnMap] = useState(true);
  const [selectedRowAttractions, setSelectedRowAttractions] = useState([]);
  const [itinerary, setItinerary] = useState([]);
  const [selectedDay, setSelectedDay] = useState('Day 1:');
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedAttraction, setSelectedAttraction] = useState(null);
  const [newCardDetails, setNewCardDetails] = useState('');
  const [newCardName, setNewCardName] = useState('');
  const [isAddCardButtonClicked, setIsAddCardButtonClicked] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isHotelClicked, setIsHotelClicked] = useState(false);
  const [hotelMarkers, setHotelMarkers] = useState([]);
  const [days, setDays] = useState([]);


  const [itin, setItin] = useState(initialItinerary);
  if (itinerary.length == 0) {
    const updatedItinerary = { ...itin };

    Object.keys(updatedItinerary).forEach((day) => {
      ['Morning', 'Afternoon', 'Evening'].forEach((period) => {
        updatedItinerary[day][period].forEach((attraction) => {
          const attractionData = attractionsData.find(
            (data) => data["Attraction Name"] === attraction["name"]
          );
          if (attractionData) {
            attraction.lat = attractionData.lat;
            attraction.long = attractionData.lon;
          }
        });
      });
    });
  
  setItinerary(updatedItinerary);
  }

  const allAttractions = () => {
    const attractions = [];
    Object.entries(itinerary).forEach(([day, details]) => {
      attractions.push(...details.Morning, ...details.Afternoon, ...details.Evening)
    });
    return attractions;
  };


  useEffect(() => {
    const attractions = [];
    if (selectedDay && itinerary[selectedDay]) {
      Object.values(itinerary[selectedDay]).forEach((period) => {
        attractions.push(...period);
      });
    }
    setSelectedRowAttractions(attractions);
  }, [itinerary, selectedDay]);

  useEffect(() => {
    const keys = Object.keys(itinerary);
    setDays(keys);
  }, [itinerary]);

  const handleRowSelect = (attractions, rowIndex) => {
    setSelectedRow(rowIndex);
    setSelectedRowAttractions(attractions);
  };

  const handleDragStart = (e, day, period, index) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({ day, period, index }));
    e.currentTarget.classList.add(classes.draggable);

  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add(classes.draggable);
  };

  const handleDrop = (e, targetDay, targetPeriod) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    const { day: sourceDay, period: sourcePeriod, index } = data;

    if (targetDay === sourceDay && targetPeriod === sourcePeriod) {
      return;
    }

    const sourceAttractions = itinerary[sourceDay][sourcePeriod];
    const targetAttractions = itinerary[targetDay][targetPeriod];
    const attractionToMove = sourceAttractions[index];

    sourceAttractions.splice(index, 1);
    targetAttractions.push(attractionToMove);

    setItinerary({ ...itinerary });

    setSelectedDay(targetDay);
    setSelectedAttraction(attractionToMove);

    // Remove draggable class from the target card element
    e.currentTarget.classList.remove(classes.draggable);
  };


  const handleDeleteCard = (day, period, index) => {
    const updatedItinerary = { ...itinerary };
    updatedItinerary[day][period].splice(index, 1);
    setItinerary(updatedItinerary);
  };

  const handleAddCardButton = (day, period) => {
    setSelectedDay(day);
    setSelectedPeriod(period);
    setIsAddCardButtonClicked(false);

  };

  const handleBackCard = (day, period) => {
    setSelectedDay('');
    setSelectedPeriod('');
    setIsAddCardButtonClicked(false);

  }

  const handleAddCustomCard = () => {
    setIsAddCardButtonClicked(true);

  }

  const handleDaySelection = (selectedDay, hotelDetails) => {
    const updatedItinerary = { ...itinerary };
    hotelDetails.lat = "NO";
    hotelDetails.long = "NO";
    // updatedItinerary[selectedDay].Stay = [hotelDetails]; // Add the hotel details to the "Stay" row

    setItinerary(updatedItinerary);
    console.log('Selected Hotel:', hotelDetails);
  };

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove(classes.draggable);
  };

  const handleAddCard = (day, period) => {

    if (newCardName === '') {
      return;
    }

    const newnew = {};
    newnew["Attraction Name"] = newCardName;
    newnew['Attraction Details'] = newCardDetails;
    newnew["lat"] = "NO";
    newnew["long"] = "NO";
    const newItinerary = { ...itinerary };
    const updatedCardDetails = [...newItinerary[day][period], newnew];
    newItinerary[day][period] = updatedCardDetails;
    console.log(updatedCardDetails);
    setSelectedDay('');
    setSelectedPeriod('');
    setSelectedAttraction(newnew);
    setNewCardDetails('');
    setNewCardName('');
    setItinerary(newItinerary);
  };

  return (
    <div className={classes.board}>
      <div className='main-board-container' style={{ display: 'flex', height: '100%', backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
        
        <div className='board' >
        <div className='rearrange-title' style={{ flex: '1 1 2%', color: 'white', fontFamily: 'Ysabeau Infant' }}>
            Rearrange the cards to organise your trip..
          </div>
          <TableContainer>
            <Table className='table-size' style={{ tableLayout: '' }}>
              <TableHead>
                <TableRow>
                  <TableCell className='cell-padding' style={{ width: '5%' }}>
                    <AddBoxIcon  onClick={() => handleAddCustomCard()} style={{ color: '#f2ecec', fontSize: 'medium'  }} />
                  </TableCell>
                  <TableCell  className='cell-padding' align="center" style={{ color: '#a6c0c9', fontSize: 'Ysabeau Infant' }}>M O R N I N G</TableCell>
                  <TableCell  className='cell-padding' align="center" style={{  color: '#dbd0ae', fontSize: 'Ysabeau Infant' }}>A F T E R N O O N</TableCell>
                  <TableCell   className='cell-padding' align="center" style={{ color: '#d1aeae', fontSize: 'Ysabeau Infant' }}>E V E N I N G</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(itinerary).map(([day, details], rowIndex) => (
                  <TableRow
                    key={day}
                    sx={{
                      backgroundColor: selectedRow === rowIndex && showOnMap ? 'rgb(0, 0, 0, 0.3)' : '',
                      "&:hover": {
                        backgroundColor: showOnMap ? 'rgb(0, 0, 0, 0.3)' : '',
                      },
                    }}
                    onClick={() =>
                      handleRowSelect(
                        [...details.Morning, ...details.Afternoon, ...details.Evening],
                        rowIndex
                      )
                    }
                  >
                    <TableCell className='element-with-border' align="center" style={{ margin: 0, color: 'white', verticalAlign: 'middle', fontSize: 'Ysabeau Infant',  }}>{day.replace(':', '')}</TableCell>
                    {['Morning', 'Afternoon', 'Evening',].map((period) => (
                      <TableCell className='cell-padding' key={period}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave} // Call handleDragLeave when the dragging leaves the element
                        onDrop={(e) => handleDrop(e, day, period)}
                        style={{ verticalAlign: 'top' }}>
                        {details[period]?.map((attraction, index) => (
                          <ItineraryCard
                            key={index}
                            index={index}
                            attraction={attraction}
                            color={period === 'Morning' ? '#a6c0c9' : period === 'Afternoon' ? '#dbd0ae' : '#d1aeae'}
                            onDragStart={(e) => handleDragStart(e, day, period, index)}
                            onClick={() => {
                              handleRowSelect([...details.Morning, ...details.Afternoon, ...details.Evening]);
                              setSelectedAttraction(attraction);
                            }}
                            onDelete={() => handleDeleteCard(day, period, index)}
                          />
                        ))}

                        {day === selectedDay && period === selectedPeriod ? (
                          <div>
                            <TextField
                              sx={{ input: { color: 'white' } }}
                              value={newCardName}
                              onChange={(e) => setNewCardName(e.target.value)}
                              placeholder="Add card title"
                              fullWidth
                              margin="normal"
                            />
                            <TextField
                              sx={{ input: { color: 'white' } }}

                              value={newCardDetails}
                              onChange={(e) => setNewCardDetails(e.target.value)}
                              placeholder="Add card details (optional)"
                              fullWidth
                              margin="normal"
                            />
                            <Button variant="outlined" color="primary" onClick={() => handleAddCard(day, period)}
                            style={{ backgroundColor: 'rgb(0, 0, 0, 0.4)', color: 'white' }}>Add</Button>
                            <Button variant="outlined" color="primary" onClick={() => handleBackCard(day, period)}
                            style={{ backgroundColor: 'rgb(0, 0, 0, 0.4)', color: 'white' }}>Go Back</Button>

                          </div>
                        ) : (
                          isAddCardButtonClicked && (
                            <IconButton onClick={() => handleAddCardButton(day, period)}>
                              <AddBoxIcon style={{ color: '#f2ecec', fontSize: 'medium' }} />
                            </IconButton>
                          )
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
            <ViewFullItineraryButton destination={destination} itinerary={itinerary} attractionsData={attractionsData} />
          </div>
        </div>
          <div className='map-board-container' style={{ margin: '0 1% 0px 0', display: showOnMap ? 'flex' : 'none'  }}>
            <ItineraryMap allAttractions={allAttractions()}
              attractions={selectedRowAttractions}
              selectedAttraction={selectedAttraction}
              hotelMarkers={hotelMarkers}
              days={days}
              onDaySelection={handleDaySelection}
              attractionsData={attractionsData}
            />
          </div>

      </div>

    </div>
  );
};

export default ItineraryBoard;
