import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Scrollbars } from 'react-custom-scrollbars';
import { IconButton, Tooltip, Badge } from '@mui/material';
import AttractionCard from '../components/Card/AttractionCard';
import Map from '../components/Map/AttractionsMap';
import './AttractionsPage.css';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ItineraryButton from '../components/Button/ItineraryButton';
import ResponsiveAppBar from '../components/AppBar';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';




const AttractionsPage = () => {
  const params = useParams();
  const country = params.country;
  const city = params.city;
  const dest = city + ", " + country;
  const [drawerOpen, setDrawerOpen] = React.useState(false);


  const [allAttractions, setAttractions] = useState([]);
  const [catAttrObjects, setCatAttrObjects] = useState([]);
  const [purposes, setPurposes] = useState([]);

  const [activeTab, setActiveTab] = useState(null);
  const [showOnMap, setShowOnMap] = useState(true);
  const [selectedAttraction, setSelectedAttraction] = useState('');
  const [shortlist, setShortlist] = useState([]);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerHeight < 900);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerHeight < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activeTab === null) {
          const purpose = await fetch('https://selenetravel.azurewebsites.net/destination/query', {
            // const purpose = await fetch('http://localhost:5000/destination/query', {

            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ input: country, city: city }),
          });

          if (purpose.ok) {
            const result = JSON.parse((await purpose.json()).response);
            const gg = result.purposes.flat();
            setPurposes(gg);
            setActiveTab(gg[0]);
          }
        }

        const response = await fetch('https://selenetravel.azurewebsites.net/destination/purpose', {
          // const response = await fetch('http://localhost:5000/destination/purpose', {

          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ country: country, city: city, purposes: [activeTab] }),
        });

        if (response.ok) {
          const data = JSON.parse((await response.json()).response);
          const categoriesArray = Object.entries(data.categories);
          categoriesArray.sort((a, b) => a[0].localeCompare(b[0]));
          const sortedCategories = Object.fromEntries(categoriesArray);
          setCatAttrObjects(sortedCategories);
          console.log(sortedCategories);
          const allGAttractions = Object.values(data.categories).flat();
          setAttractions(allGAttractions);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (country && city) {
      fetchData();
    }
  }, [country, city, activeTab]);

  const handleChange = (_, newTab) => {
    setActiveTab(newTab);
  };

  const getAttractionsForActiveTab = () => {
    if (activeTab === 'all') {
      return allAttractions;
    }
    if (activeTab == 'shortlisted') {
      return getShortlistedItems();
    }
    
    return catAttrObjects[activeTab] || [];
  };

  const handleMarkerClick = (attractionName) => {
    setSelectedAttraction(attractionName);
    const cardRef = document.getElementById(attractionName);
    if (cardRef) {
      cardRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMouseEnter = (attractionName) => {
    setSelectedAttraction(attractionName);
  };

  const handleCardClick = (attractionName) => {
    setSelectedAttraction(attractionName);
  };

  const addOrRemoveFromShortlist = (attraction) => {
    const isAttractionInShortlist = shortlist.includes(attraction);

    if (isAttractionInShortlist) {
      const updatedShortlist = shortlist.filter((item) => item !== attraction);
      setShortlist(updatedShortlist);
    } else {
      const updatedShortlist = [...shortlist, attraction];
      setShortlist(updatedShortlist);
    }
  };

  const getShortlistedItems = () => {
    return allAttractions.filter((attraction) =>
      shortlist.includes(attraction.name)
    );
  };

  const handleShortlistedItemsTab = () => {
    setActiveTab('shortlisted');
  };

  const handleMenuClick = () => {
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className='countries-page'>
      {/* <ResponsiveAppBar destination={dest} shortlist={shortlist} attractionsData={getShortlistedItems()}/> */}
    <div style={{ width: '100%', height: '95%'  }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '2%', padding: '1% 3% 0% 1%'}}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          mr: 'auto',
          display: 'flex',
        }}
      >
        <Tab
          key="shortlisted"
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Badge badgeContent={shortlist.length} color="secondary" anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
                <BookmarksIcon style={{ marginRight: '0.5rem', color: 'rgb(98 89 210)' }} />
              </Badge>
              <span className='tab-title'>My Trip</span>
            </div>
          }
          value="shortlisted"
          onClick={handleShortlistedItemsTab}
        />
         {purposes.map((category) => (
          <Tab key={category} label={
            <div className='tab-title'>
            {category}
            </div>
          } value={category} />
        ))} 
      </Tabs> 
      {/* )} */}

      <div style={{flex: 'flex-end'}}><ItineraryButton destination={dest} shortlist={shortlist} attractionsData={getShortlistedItems()}/></div>
    </div>

      <div className='attr-main-container' style={{ display: 'flex', width: '100%', height: '90%' }}>
        <div className='container-attr'>         
          <Scrollbars 
            style={{ width: '100%', height: '100%' }} 
            renderView={({ style, ...props}) => {
              return <div style={{...style, overflowX: 'hidden'}} {...props}/>;
            }}
          >
              {getAttractionsForActiveTab().map((attraction) => (
                    <div key={attraction.name} id={attraction.name}>
                      <AttractionCard
                        destination={country}
                        attractionName={attraction.name}
                        description={attraction.description}
                        image_urls={attraction.image_urls}
                        isSelected={selectedAttraction === attraction.name}
                        highlightedAttr={handleMouseEnter}
                        isInShortlist={shortlist.includes(attraction.name)}
                        isShortlistActive={activeTab == 'shortlisted'}
                        onCardClick={handleCardClick}
                        addToShortlist={addOrRemoveFromShortlist}

                      />
                    </div>
                  ))}
                  
            </Scrollbars>
          {/* </Box> */}
        </div>

        <div className='map-attr-container' style={{ display: showOnMap ? 'block' : 'none' }}>
          <Map 
            attractions={getAttractionsForActiveTab()}
            selectedAttraction={selectedAttraction}
            shortlist={shortlist} 
            onMarkerClick={handleMarkerClick}
            allAttractions={allAttractions}
          />
        </div>
      </div>
    </div>

    <div style={{position: 'relative'}}>
      <footer style={{color: '#b2acac', display: 'flex', flexDirection: 'row', position: 'relative', bottom: '-19px' }}> <InfoRoundedIcon sx={{fontSize: 'larger', margin: '3px 0px 3px 0px',}}/> <span style={{fontSize: '78%', padding: '3px'}}>Cross-check important info.</span></footer>
    </div>
    </div>
  );
};

export default React.memo(AttractionsPage);
