import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { useLocation } from 'react-router-dom';
import InputCitiesList from './components/InputField/InputCitiesList';
import AttractionsPage from './pages/AttractionsPage';
import ItineraryPage from './pages/ItineraryPage';
import CountriesPage from './pages/CountriesPage';
import '@fontsource/princess-sofia';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import ExportItineraryPage from './pages/ExportItineraryPage';
import ContactButton from './components/ContactUs/Contact';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import ReactGA from 'react-ga';
import Guide from './components/Guide/Guide';


const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-NXC0CQTDY4');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const shouldDisplayAppBar = location.pathname != "/"


  return (
    <div>
    <div className={!shouldDisplayAppBar ? 'App' : ''} >
    {!shouldDisplayAppBar && (<ContactButton style={{marginTop: '5%'}}></ContactButton>)}
      {!shouldDisplayAppBar && (
        <div className="app-title">
            Stanza            
        </div>
      )}

      <Routes>
        <Route path="/" element={<InputCitiesList />} />
        <Route path="/countries" element={<CountriesPage />} />
        <Route path="/attractions/:country/:city" element={<AttractionsPage />} />
        <Route path="/itinerary" element={<ItineraryPage />} />
        <Route path="/exportitinerary" element={<ExportItineraryPage />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
      </Routes>
    </div>

    {!shouldDisplayAppBar && (
      <div>
    <div className='scrolltext'>
          Scroll if you don't know what to do here..
      
          </div>
    <Guide></Guide>
    </div>
    )}

    </div>
  );
};

export default React.memo(App);