// DownloadButton.js
import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import '../Button/Button.css';

const ProductDisplay = (destination, itinerary, showOnMap, attractionsData) => (
  <section>
    <form action="https://selenetravel.azurewebsites.net/destination/create-checkout-session" method="POST">
    {/* <form action="http://127.0.0.1:5000/destination/create-checkout-session" method="POST"> */}

    <input type="hidden" name="destination" value={JSON.stringify(destination)} />
      <input type="hidden" name="itinerary" value={JSON.stringify(itinerary)} />
      <input type="hidden" name="attractionsData" value={JSON.stringify(attractionsData)} />
      <Tooltip title="Export to PDF">
      <Button
      className='export-button-size'
        type="submit"
        variant='filled'
        sx={{
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.6)',
          borderRadius: '15px',
          marginLeft: '0.6%',
          color: '#8682c2',
        }}
      >
        <span className='export-button-size'>Itinerary</span><GetAppIcon className='export-icon-size' />
      </Button>
    </Tooltip>
    </form>
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const DownloadButton = ( destination, itinerary, showOnMap, attractionsData) => {
  const [message, setMessage] = useState("");

  const [token, setToken] = useState(null);


  const handleDownload = async () => {
    try {
      const response = await fetch(`https://selenetravel.azurewebsites.net/destination/success?id=${token}`);
      const blob = await response.blob();

      // Create a blob URL and open it in a new window
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');

      setMessage('PDF downloaded successfully!');
      
    } catch (error) {
      console.error('Error downloading PDF:', error.message);
      setMessage('Error downloading PDF');
    }
  };


  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const id = query.get("id");
      setToken(id);

    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  useEffect(() => {
    if (token) {
      handleDownload();
    }
  }, [token]);

  return message ? (
    <Message message={message} />
  ) : (
    // <Message message={message} />
    <ProductDisplay destination={destination} itinerary={itinerary} showOnMap={showOnMap} attractionsData={attractionsData}/>
  );
};

export default DownloadButton;
