// AttractionList.js
import React from 'react';
import ExportAttractionCard from './ExportAttractionCard';
import './ExportItineraryPage.css';

const AttractionList = ({ attractions, showOnMap, attractionsData, name }) => {

  const isLineBreakNeeded = window.innerWidth > 300 && window.innerHeight < 900;

  return (
  <div style={{ marginBottom: '2%' }}>
    {/* <div style={{ marginLeft: showOnMap ? '3.5%' : name == 'A F T E R N O O N' ? '7%' : '8%', marginBottom: '3%', fontFamily: 'trebuchet ms' }}><h5>{name}</h5></div> */}
    
    {/* <div style={{display: 'flex', flexDirection: 'row'}}> */}
      {/* <div style={{flex: '1 1 18%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '6%', paddingRight: '10px'}}> */}
      <div style={{ paddingLeft: '6%', paddingRight: '10px'}}>

    {/* <div className='export-image-container' style={{height: '0px', paddingBottom: '11%'}}> */}
    <div className={name== 'A F T E R N O O N' && showOnMap ? 'daytime-showmap-af': name == 'A F T E R N O O N' && !showOnMap ? 'daytime-container-af': name != 'A F T E R N O O N' && showOnMap ? 'daytime-showmap' : 'daytime-container' }>
      {name}
      </div>
      {/* </div> */}
    {/* </div> */}
    {/* <div style={{flex: '1 1 75%'}}></div> */}
    </div>
    {!isLineBreakNeeded && <br />}
    {attractions.map((attraction, index) => 
    <ExportAttractionCard attraction={attraction} showOnMap={showOnMap} index={index} attractionsData={attractionsData} 
    />
    )}
  </div>
  );
};

export default AttractionList;
