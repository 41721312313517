import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Map from '../components/Map/CountryMap';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../components/AppBar';
import CardRow from '../components/Card/CountryImageCard';
import './CountriesPage.css';
import ImageListItem, {
  imageListItemClasses
} from "@mui/material/ImageListItem";

import Modal from 'react-modal';

Modal.setAppElement('#root');

const CountriesPage = () => {
  const navigate = useNavigate();
  console.log('lol')

  const location = useLocation();
  const destination = location.state?.destination;

  const data = JSON.parse(location.state?.response);

  const [selectedAttraction, setSelectedAttraction] = useState('');
  const [highlightedAttraction, setHighlightedAttraction] = useState('');
  const [responses, setResponse] = React.useState('');

  const handleMarkerClick = (attractionName) => {
    setSelectedAttraction(attractionName);
    setHighlightedAttraction(attractionName);

    const cardRef = document.getElementById(attractionName);
    if (cardRef) {
      cardRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCardClick = (attractionName) => {
    setSelectedAttraction(attractionName);
    setHighlightedAttraction(attractionName);
  };

  const handleCardHover = (attractionName) => {
    setHighlightedAttraction(attractionName);
  };

  const handleExploreClick = async (city) => {
    var country = destination
    navigate(`/attractions/${country}/${city}`, { state: { country: country, city: city } });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 900);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='countries-page'>
      <ResponsiveAppBar destination={destination} />

      <div className='main-container' style={{ display: 'flex' }}>
        <div className='container' style={{ flex: 'auto', marginLeft: '0px' }}>

          <Box className='countries-box'  style={{
            backgroundColor: isMobile ? 'white': "rgba(0, 0, 0, 0.3)",
            borderRadius: isMobile ? '13px': '0px',
            display: "grid" }}>
            <CardRow country={destination} places={data.cities} selectedCity={handleCardClick} highlightedCity={handleCardHover}
              selectedAttraction={selectedAttraction}
              onExploreClick={handleExploreClick}
            />
          </Box>
        </div>

        <div className='map-container' backgroundColor="rgba(0, 0, 0, 0.4)" style={{ flex: 'auto',  }}>
          <Map
            attractions={data.cities}
            selectedAttraction={highlightedAttraction}
            onMarkerClick={handleMarkerClick}
            allAttractions={data.cities}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CountriesPage);
