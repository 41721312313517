import React from 'react';
import { Button } from '@mui/material';

const ContactButton = () => {
  const emailAddress = 'tourstanza@outlook.com';

  const handleContactClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (

    <div style={{padding: '15px', 
    position: 'fixed',
    top: '0',
    right: '0'}}>

    <Button
        size="small"
        variant='filled'
        onClick={handleContactClick}
        sx={{
          backgroundColor: 'rgb(0, 0, 0, 0.9)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.6)',
          borderRadius: '40px',
          color: 'white',
          
        }}
      >
      Contact Us
      </Button>
      </div>
  );
};

export default ContactButton;
