import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Box, Button } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import PlaceIcon from '@mui/icons-material/Place';
import ImageCarousel from './ImageCarousel';
import GoogleCSE from './GoogleSE';
import GoogleCSESmall from './GoogleSEsmall';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import './Card.css';

const AttractionCard = ({
  destination,
  attractionName,
  description,
  image_urls,
  isSelected,
  isInShortlist,
  highlightedAttr,
  isShortlistActive,
  onCardClick,
  addToShortlist
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredCard, setHoveredCard] = React.useState(null);

 
  const handleMouseEnter = () => {
    setIsHovered(true);
    setHoveredCard(attractionName);
    highlightedAttr(attractionName);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth > 300 && window.innerWidth < 900);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 300 && window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const footer = (
    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        variant={isInShortlist ? 'contained' : 'outlined'}
        style={{
          padding: '3px 10px',
          fontSize: window.innerWidth > 300 && window.innerHeight < 900 ? '45%':'70%',
          borderRadius: '30px',
          color: isInShortlist ? 'white' : 'white',
          borderColor: isInShortlist ? 'blue' : '',
          backgroundColor: isInShortlist ? 'rgb(98 89 210)' : isMobile ? 'black' :'rgb(0, 0, 0, 0.7)',
          borderWidth: '1px'
        }}
        onClick={() => addToShortlist(attractionName)}
      >
        {isInShortlist ? (isShortlistActive ? 'Remove from my trip' : 'In my trip') : 'Add to Trip'}
      </Button>
    </Box>
  );

  const title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>

      <PlaceIcon sx={{ verticalAlign: 'middle', color: '#3b8aa5ed', 
      fontSize: '100%',
      marginRight: '2px' }} />
      <div className='card-title' >{attractionName}</div>

    </div>
  );

  const handleCardClick = () => {
    onCardClick(attractionName);
  };

  const cardClass = isSelected ? 'selected-card' : '';



  const getFirstLetter = (str) => {
    return str.charAt(0).toUpperCase();
  };

  const getTruncatedText = (str) => {
    const words = str.split(' ');
    return words.slice(0, 15).join(' ');

  }

  return (
    <div onClick={handleCardClick} 
    onMouseEnter={handleMouseEnter} 
    onMouseLeave={handleMouseLeave}>
      <Card className={cardClass} title={
        <div>{title}</div>
      } style={{
        marginBottom: window.innerWidth > 300 && window.innerHeight < 900 ? '1em' : '2em', 
        backgroundColor: window.innerWidth > 300 && window.innerHeight < 900 ? '' : "rgb(0, 0, 0, 0.5)", padding: '1%',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)',
        background: isHovered ? window.innerWidth > 300 && window.innerWidth < 900 ? 'rgb(205 206 206 / 40%)' : "rgb(0, 0, 0, 0.55)" : window.innerWidth > 300 && window.innerWidth < 900 ? 'rgb(245 245 245 / 40%)' : "rgb(0, 0, 0, 0.4)",
        cursor: isHovered ? 'pointer' : 'default',
        margin: isMobile ? '4%': '0 0 2em',
      }}>


        {image_urls && image_urls[0]?.url ? (
          <div>
            <div style={{ display: 'flex' }}>
              <div className='attr-image-container' style={{ position: 'relative'}}>
                <CardMedia component="img" sx={{width: '100%', height: '100%', borderRadius: '10px', marginLeft: '4%' }} image={image_urls[0]?.url} loading="lazy" />
                <div style={{ position: 'absolute', bottom: '0', left: '0' }}>
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)',
                  }}
                    title={image_urls[0]?.credit_line}
                  >
                    <InfoIcon sx = {{ 
                      fontSize: window.innerWidth > 300 && window.innerWidth < 900 ? '1rem': '1.5rem',
}}/>
                  </IconButton>
                </div>
              </div>
              <div className="card-details" style={{
                color: window.innerWidth > 300 && window.innerWidth < 900 ? '#535357' : '#9f9e9e',
                marginLeft: window.innerWidth > 300 && window.innerWidth < 900 ? '' : '2%',
              }} >
                {description}
              </div>
            </div>
            {/* {image_urls && image_urls.length > 0 && (
              <div className="small-cse" style={{ height: window.innerWidth > 300 && window.innerHeight < 900 ? 0 : 30, width: 210 }}>
                <GoogleCSESmall id={`google-cse-${attractionName}`} query={attractionName + ' ' + destination} />
              </div>
            )} */}
          </div>
        ) : (
          <div>
          <div style={{ display: isMobile ? 'none' : 'flex' }}>
            <div style={{ position: 'relative' }}>
              {/* <CardMedia component="img" sx={{ width: 230, height: 190, borderRadius: '10px', marginLeft: '4%' }} image={image_urls[0]?.url} loading="lazy" /> */}
              <div className="big-cse" style={{ height: 190, width: 190, paddingLeft: '9%', marginBottom: '2%' }}>
                <GoogleCSESmall id={`google-cse-${attractionName}`} query={attractionName + ' ' + destination} />
              </div>
            </div>
            <div style={{ flex: 1, marginLeft: '4%', color: '#a0a4a9',  }}>
              <div className="card-details">
                <p>{description}</p>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>

          <div className='attr-image-container' style={{ position: 'relative', display: isMobile ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              // backgroundColor: '#9491914d',
              borderRadius: '10px',
              width: '100%',
              height: '75px',
              fontSize: 'small',
              color: '#61567c',
              // marginTop: '15%',
            }}>
              {getFirstLetter(attractionName)}
            </div>
            <div className="card-details" style={{
              display: isMobile ? 'block' : 'none',
                color: window.innerWidth > 300 && window.innerHeight < 900 ? '#535357' : '#9f9e9e',
              }} >
                {description}
              </div>
            </div>
            

        </div>
          )}

        <div>{footer}</div>
      </Card >
    </div >
  );
};

export default AttractionCard;
