import React from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { hover } from '@testing-library/user-event/dist/hover';
import './Button.css';

const ViewFullItineraryButton = ({ destination, itinerary, attractionsData }) => {
  const navigate = useNavigate();


  const handleViewItitneraryClick = () => {
    navigate('/exportitinerary', { state: { response: JSON.stringify(itinerary), destination: destination, attractionsData: attractionsData } });
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%', marginBottom: '2%' }}>
      <Button className='view-itinerary-button' variant="contained" color="primary" onClick={handleViewItitneraryClick}
        sx={{
          borderRadius: '10px',
          backgroundColor: '#6259d2',
          cursor: 'pointer',
          "&:hover": { backgroundColor: '#38374e' }
        }}>
        View Full Itinerary
      </Button>
    </div>
  );
};

export default ViewFullItineraryButton;
